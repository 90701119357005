import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Markdown from 'markdown-to-jsx';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { articles } from '../../contents'

const BlogRead = () => {
	const [post, setPost] = useState('');
	const [body, setBody] = useState('');
	// const [loading, setLoading] = useState(false);

	const { slug } = useParams();

	useEffect(() => {
		Object.entries(articles).map((value, key) => {
			if (value[1].slug === slug) {
				setBody(value[1]);

				import(`../../contents/md-articles/${value[1].file_name}`)
					.then((res) => {
						fetch(res.default)
							.then((res) => res.text())
							.then((res) => setPost(res));
					})
					.catch((err) => { });
			};
		});
	}, []);

	return (
		<>
			<Header />
			<Helmet>
				<title>{body?.title}</title>
				<meta
					name="description"
					content={body.short_description}
				/>
				<meta
					name="keywords"
					content={body?.keywords}
				/>
			</Helmet>
			<div className="page-content bg-white">

				{/* {loading ? (<p>Loading...</p>) : ( */}
				<div className="content-area">
					<div className="container max-w900">
						<div className="blog-post blog-single">

							<div className="dlab-post-media">
								<img style={{
									width: "100%",
									height: 200,
									objectFit: "cover"
								}} src={body.image} alt={body.title} />
							</div>
							<div className="mt-4 dlab-post-title ">
								<h1 className="post-title m-t0">{body.title}</h1>
							</div>
							<div className="text-dark">
								{post && <Markdown options={{ wrapper: 'article' }}>
									{post}
								</Markdown>}
							</div>
							<div className="dlab-post-tags clear">
								<div className="post-tags">
									{body.tags && body?.tags.map((res, i) => { return (<Link key={i}>{res}</Link>) })}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default BlogRead;
