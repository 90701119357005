import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { courses } from '../../contents';

class Courses extends Component {


    render() {
        return (
            <>
                <Header active="courses" />
                <div className="page-content bg-white">

                    <div className="content-area">
                        <div className="container text-black">
                            <h2>Courses</h2>
                            <p>Free and paid Courses for Mobile, Web, Cloud and programming in general</p>
                            <div className="row">
                                <div className="col-lg-12">
                                    {courses.length === 0 && (<><h2 className='text-center'>There are no courses published yet, please check back later. </h2> <p className='text-center'>Click <a style={{ color: "blue" }} href="https://anayooleru-newsletter.beehiiv.com/subscribe">here</a> to subscribe and keep up with when something drops</p></>)}

                                    {courses.map((course, index) => {
                                        return (
                                            <div className="blog-post blog-md clearfix">
                                                <div className="dlab-post-media dlab-img-effect zoom-slow">
                                                    <Link to={`/course/start/${course.slug}`}><img src={course.image} alt="" /></Link>
                                                </div>
                                                <div className="dlab-post-info">
                                                    {/* <div className="dlab-post-meta">
                                                        <ul>
                                                            <li className="post-author"><Link to="/#">Free</Link> </li>
                                                        </ul>
                                                    </div> */}
                                                    <div className="dlab-post-title">
                                                        <h4 className="post-title"><Link to={`/course/start/${course.slug}`}>{course.title}</Link></h4>
                                                    </div>
                                                    <div className="dlab-post-text">
                                                        <p>{course.short_description}</p>
                                                    </div>
                                                    <div className="dlab-post-readmore">
                                                        <Link to={`/course/start/${course.slug}`} title="READ MORE" rel="bookmark" className="site-button">START COURSE
                                                            <i className="ti-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {/* <div className="pagination-bx clearfix m-b30 text-center">
                                        <ul className="pagination">
                                            <li className="active next"><Link to="/#">More <i className="ti-arrow-down"></i></Link></li>
                                        </ul>
                                    </div> */}
                                </div>

                                {/* <Sidebar/> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </>
        );
    };
};

export default Courses;
