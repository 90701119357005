import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import 'react-modal-video/scss/modal-video.scss';

import ScrollToTop from '../element/scrollToTop';

class Academy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }


    openModal = (event) => {
        event.preventDefault()
        this.setState({ isOpen: true })
    }

    closeModal = () => {
        this.setState({ isOpen: false })
    }

    render() {
        return (
            <>
                <Header active={'academy'} />
                <div class="page-content bg-white">
                    <div class="content-block">

                        <div class="section-full">
                            <div class="row m-lr0">
                                <div class="col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info bg-primary wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div class="text-white align-self-center ">
                                        <h2>ASO Academy Career Accelerator Program</h2>
                                        <p><span class="text-white">ASO Academy is a free 9 months coding program that covers technical and soft skills needed to thrive in software engineering.
                                            <br />
                                            <br />
                                            Anayo Samson Oleru established this program to support 20 carefully chosen individuals annually, guiding them toward becoming top-notch engineers. The objective is to train a total of 20 engineers within the next two years. Participants acquire the ability to collaborate with engineering teams and excel in the professional and technical skills essential for advancing their careers.
                                            <br />
                                            <br />
                                            I actively seek and embrace exceptional individuals from diverse backgrounds who are dedicated to realizing their full potential and harnessing technology to make a positive impact on the world.</span></p>
                                        <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSezwJ6aAUUA-EdxFeP_UfP1cjZQw-qKFhXHGS0dIO3Kryn8Jw/viewform" }} target={"_blank"} class="site-button btnhover13 white">Apply now</Link>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12 p-lr0">
                                    <div class="dlab-box">
                                        <div class="dlab-thum">
                                            <Link to="#"> <img class="img-cover" style={{ height: 780 }} src={"https://images.unsplash.com/photo-1616596875678-9425439b2f1e?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="" /> </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="section-full content-inner">
                            <div class="container">
                                <div class="section-head text-black text-center">
                                    <h2 class="title">About the program</h2>
                                    <p>The program comprises six cycles annually, with an intake of 3-5 students per cycle. I personally mentor each ASO student throughout their professional journey. If you're interested in joining the program, please review the process outlined below. 
                                        If you <a href="mailto:asoacademy@outlook.com" target={"_blank"} style={{ textDecoration: 'underline', color: 'blue' }} rel="noreferrer">Send email</a></p>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6 m-b30 wow bounceInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div class="icon-bx-wraper sr-iconbox1">
                                            <div class="icon-lg m-b20 icon-up">
                                                {/* <Link to="#" class="icon-cell"><img src={require("../../images/icon/food/icon1.png")} alt="" /></Link> */}
                                                <b>1</b>
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Application</h4>
                                                <p>Complete an <a href={"https://docs.google.com/forms/d/e/1FAIpQLSezwJ6aAUUA-EdxFeP_UfP1cjZQw-qKFhXHGS0dIO3Kryn8Jw/viewform"} target={"_blank"} style={{ textDecoration: 'underline', color: 'blue' }} rel="noreferrer">online application</a>. On the online form there will be a display of the cycle you are applying for and link to the <a href='https://homestudy.anayooleru.com/' target={"_blank"} style={{ textDecoration: 'underline', color: 'blue' }} rel="noreferrer">Homestudy</a>, which you must study to prepare for the technical assessment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 m-b30 wow bounceInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div class="icon-bx-wraper sr-iconbox1">
                                            <div class="icon-lg m-b20 icon-up">
                                                {/* <Link to="#" class="icon-cell"><img src={require("../../images/icon/food/icon2.png")} alt="" /></Link> */}
                                                <b>2</b>
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Technical Assessment</h4>
                                                <p>Based on your study of the <a href='https://homestudy.anayooleru.com/' target={"_blank"} style={{ textDecoration: 'underline', color: 'blue' }} rel="noreferrer">Homestudy</a>, candidate will then be sent a technical test. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 m-b30 wow bounceInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div class="icon-bx-wraper sr-iconbox1">
                                            <div class="icon-lg m-b20 icon-up">
                                                {/* <Link to="#" class="icon-cell"><img src={require("../../images/icon/food/icon3.png")} alt="" /></Link> */}
                                                <b>3</b>
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Interview</h4>
                                                <p>To get to know you personally, validate your technical test and your study of the HomeStudy, there will be a virtual Interview with me or/and other guest engineers from top organizations or a previous cycle.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 m-b30 wow bounceInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div class="icon-bx-wraper sr-iconbox1">
                                            <div class="icon-lg m-b20 icon-up">
                                                {/* <Link to="#" class="icon-cell"><img src={require("../../images/icon/food/icon4.png")} alt="" /></Link> */}
                                                <b>4</b>
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Core Concepts Phase</h4>
                                                <p>Successful candidates are invited to an 8-week core concepts phase. Building a solid foundation in web development and teamwork essentials.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 m-b30 wow bounceInDown" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div class="icon-bx-wraper sr-iconbox1">
                                            <div class="icon-lg m-b20 icon-up">
                                                {/* <Link to="#" class="icon-cell"><img src={require("../../images/icon/food/icon5.png")} alt="" /></Link> */}
                                                <b>5</b>
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Team Project Phase</h4>
                                                <p>Partake in a 14-week active development of team projects to practice your new technical skills.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 m-b30 wow bounceInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div class="icon-bx-wraper sr-iconbox1">
                                            <div class="icon-lg m-b20 icon-up">
                                                <b>6</b>
                                            </div>
                                            <div class="icon-content">
                                                <h4 class="dlab-tilte">Gain Professional Experience Phase</h4>
                                                <p>Gain experience working with distributed teams on real-world products and active users over the next 14 weeks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.9s">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-9 text-white">
                                        <h2 class="title">Take your next step and complete our application</h2>
                                        <p class="m-b0">Unlock your full potential</p>
                                    </div>
                                    <div class="col-lg-3 d-flex">
                                        <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSezwJ6aAUUA-EdxFeP_UfP1cjZQw-qKFhXHGS0dIO3Kryn8Jw/viewform" }} target={"_blank"} class="site-button white align-self-center outline ml-auto outline-2 btnhover14">Apply Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer/>   */}
                <ScrollToTop className="style1 radius" />
            </>
        )
    }
}
export default Academy;
