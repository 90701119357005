import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import responsive from './slider-resonsive'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class Testmonial10 extends Component {

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            responsive: responsive()
        };
        return (
            <>
                <Slider {...settings} className="testimonial-two-dotsl dots-long d-primary btn-style-1">
                    {this.props.reviews.map((item, id) => (
                        <div className="item p-a5 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                            <div className="testimonial-9">
                                <div className="testimonial-pic radius style1">
                                    <img src={item.image} width="100" height="100" alt="" />
                                </div>
                                <div className="testimonial-text">
                                    <p>{item.body}</p>
                                </div>
                                <div className="testimonial-detail">
                                    <strong className="testimonial-name">{item.full_name}</strong>
                                    <span className="testimonial-position">{item.designation}</span>
                                    <span className="testimonial-position">{item.role}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default Testmonial10;