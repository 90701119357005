export const openSourceIntro = {
    title: "My Open Source Projects",
    description: "Get multiple ways to solve your problem, test your mettle and learn more advanced methods. My open-source projects is open to contributors of varying skill levels and expertise."
};

export const openSource = [
    {
        id: "1",
        title: "React Native Movie Component",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673375181/Simulator_Screen_Shot_-_iPhone_13_Pro_Max_-_2023-01-10_at_18.25.12.png",
        short_description: "A Home Movie streaming component for your React native app",
        npm_url: "https://github.com/AnayoOleru/React-native-movie",
        published: true
    },
    {
        id: "2",
        title: "React Native Authentication Screens",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673398220/Simulator_Screen_Shot_-_iPhone_13_Pro_Max_-_2023-01-11_at_00.45.50.png",
        short_description: "Authentication Components for your react-native app",
        npm_url: "https://github.com/AnayoOleru/react-native-authentication-screens",
        published: true
    },
    {
        id: "3",
        title: "React Native Onboarding Screens",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673426749/Simulator_Screen_Shot_-_iPhone_13_Pro_Max_-_2023-01-11_at_08.44.22.png",
        short_description: "Smooth and intuitive onboarding screen for your react native app",
        npm_url: "https://github.com/AnayoOleru/React-native-onboarding-screen",
        published: true
    },
    {
        id: "4",
        title: "React Native Reviews",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673365046/Simulator_Screen_Shot_-_iPhone_13_Pro_Max_-_2023-01-10_at_15.36.25.png",
        short_description: "A component for Customer or Client Reviews for your React-native app",
        npm_url: "https://github.com/AnayoOleru/React-native-reviews",
        published: true
    },
    {
        id: "5",
        title: "React Native Image Video Grid Viewer",
        image: "https://camo.githubusercontent.com/3bc5ac63200bad4fea9892456a196b00dd4796ea0d271ca84c69ff75c9430597/68747470733a2f2f7265732e636c6f7564696e6172792e636f6d2f64766672307a3877722f696d6167652f75706c6f61642f76313634353239323339302f53696d756c61746f725f53637265656e5f53686f745f2d5f6950686f6e655f31335f2d5f323032322d30322d31395f61745f31362e33332e32342e706e67",
        short_description: "This is a component for IOS and Android images and videos grid layout. Can be installed in both Expo managed, bare and react-native cli",
        npm_url: "https://www.npmjs.com/package/@leafletui/rn-image-video-grid-viewer",
        published: true
    },
    {
        id: "6",
        title: "React Native View Image",
        image: "https://blog.harveydelaney.com/content/images/2020/01/npm.jpg",
        short_description: `React Native Image and Video Viewing component Library for IOS and Android. With this component, you can view multiple images and videos and slide through them.

        Support for Expo managed, Expo bare, and React Native CLI.`,
        npm_url: "https://www.npmjs.com/package/@leafletui/react-native-view-image",
        published: true
    },
    {
        id: "5",
        title: "Quicksi CLI",
        image: "https://quicksi-bot.vercel.app/img/quicksi.png",
        short_description: "Quicksi is a command line tool with reuseable starters in different languages, frameworks and libraries designed to make it easy for developers to start projects without having to worry about the setup.",
        npm_url: "https://quicksi-bot.vercel.app/",
        published: true
    },
];
