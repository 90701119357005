export const homepage = {
    name: "Articles",
    short_description: "Gain knowledge in architecture, cloud concepts",
    profile_picture: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1696106146/profile.jpg",
    full_name: "Anayo S. Oleru",
    career: "Software Solution Architect",
    twitter: "https://twitter.com/AnayoOleru",
    linkedin: "https://www.linkedin.com/in/anayooleru/",
    instagram: "https://www.instagram.com/anayooleru/",
    youtube: "https://www.youtube.com/channel/UCHRZACg87aT_16GVD9G4-6Q",
    github: "https://github.com/AnayoOleru"
};
