export const eventsIntro = {
    title: "Past Speaking Events",
    description: ""
};

export const eventsIntroNew = {
    title: "2024 Upcoming Speaking Events",
    description: ""
};

export const events = [
    {
        id: "1",
        title: "Codementor",
        talk_theme: "Beyond SQL and NoSQL: Exploring Database Diversity",
        location: "Online",
        dataTime: 'Fri January 12, 2024 - 8:00 PM - 8:45 PM (UTC +0)',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704124867/Screenshot_2024-01-01_at_4.00.23_PM.png",
        short_description: `This talk will cover indepth
        SQL vs. NoSQL and their diversity: Highlighting distinctions, use cases, and strengths,
        the pros and cons breakdown of SQL and NoSQL databases,
        key considerations for selecting the ideal database and 
        common mistakes and pitfalls to avoid in database selection.`,
        events_url: "https://www.codementor.io/events/beyond-sql-and-nosql-exploring-database-diversity-i84run5tgb",
        published: true,
        deadline: false
    },
    {
        id: "2",
        title: "International Javascript Conference 2024",
        talk_theme: "DEMYSTIFYING WHATSAPP END-TO-END ENCRYPTION",
        location: "London, England",
        dataTime: 'Wednesday April 10, 2024 - 4:00 PM - 4:45 PM (UTC +0)',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704125704/Screenshot_2024-01-01_at_4.14.45_PM.png",
        short_description: `In an era of heightened privacy concerns, end-to-end encryption has become a central feature of many messaging platforms, and WhatsApp is no exception. WhatsApp end-to-end encryption isn't as simple as it sounds. This talk will unravel the intricacies of WhatsApp's end-to-end encryption, making this often misunderstood concept accessible to all. We will delve into the fundamental principles, security measures, and technical underpinnings of this encryption method, shedding light on how WhatsApp ensures your messages remain confidential and secure. You will learn how to use WhatsApp's same principles in your application development or architecture. Join me and we will demystify WhatsApp's end-to-end encryption and empower you with a deeper understanding of the technology that safeguards your digital conversations.`,
        events_url: "https://javascript-conference.com/general-web-development/whatsapp-end-to-end-encryption/",
        published: true,
        deadline: false
    },
    {
        id: "3",
        title: "International Javascript Conference 2023",
        talk_theme: "ANSWERS TO THE MOST POPULAR GOOGLE SEARCHES ABOUT DOMAIN DRIVEN DESIGN",
        location: "London, England",
        dataTime: 'April 25, 2023',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673468548/Screenshot_2023-01-11_at_8.21.59_PM.png",
        short_description: `What about Domain Driven Design, architecture, microservices, principles, and expectations when a company uses the DDD approach? There are so many good questions and confusion about Domain Driven Design and the list of items above. Are they any different? Are they similar in some way? What is Domain Driven Design? What questions have many people asked about Domain Driven Design? In my session, I will talk in detail about Domain Driven Design and compare and contrast it with microservices.  I will answer popular Google questions about DDD. At the end of my session, users will have good knowledge of what Domain Driven Design is, and the similarities and differences with other terminology or architecture. And lastly, they get satisfying answers to their anxious questions.`,
        events_url: "https://www.youtube.com/watch?v=_vANX-l-Y8Y&t=1960s",
        published: true,
        deadline: true
    },
    {
        id: "4",
        title: "Colorado Springs Tech (un)Conference 2020",
        talk_theme: "Architecture of a React Native app for scale",
        location: "Online - YouTube",
        dataTime: 'October 06, 2020 - 7:00 PM - 8:00 PM (MST)',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704126494/app-for-scale.jpg",
        short_description: `A React-Native project is very different from a React project from the way it should be structured, and because of this a lot of new developers have found it daunting to organize a React-Native project that won't haunt them later on or that would be difficult to scale. Organizing a file structure is one of the important initial parts of the success of a project. This talk will analyze in detail, Primary goals to keep in mind when organizing a react-native project. Primary goals that have worked for me. It'll help beginner react-native developers understand how to structure a React-Native project, containing any type of a file, or when integrating any application state management library. I’ll analyze how to set up a react-native mini-project and a large project. At the end of this talk, young and intermediate and senior developers will be able to learn something very new to take when developing any new project.`,
        events_url: "https://www.youtube.com/watch?app=desktop&v=JcRHhEF2dvI",
        published: true,
        deadline: true
    },
   
    {
        id: "5",
        title: "International Javascript Conference 2021",
        talk_theme: "Digging for NodeJS Gems",
        location: "London - England",
        dataTime: 'April 20, 2021 - 15.00 – 15.45 BST',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704129034/Screenshot_2024-01-01_at_4.53.48_PM.png",
        short_description: `"The truth is if you think you know NodeJS well, read the docs." This is what I tell my friends and mentees. Packages have abstracted most things for us, of course, that's the showers of love and gifts from the awesome community. But definitely, there will be a time when a root knowledge of NodeJS will come in handy. Perhaps you could have quickly suppressed that client's issue and save your company's money and client, only if you have that particular NodeJS root knowledge. So now more than ever is the time to look into them. In this talk, we will together explore the gems I found after digging into NodeJS docs and what cases or scenarios they can be helpful for. Glad I did and glad and motivated you will also be when you hear about them.`,
        events_url: "https://virtualdreamin.com/differences-between-a-junior-mid-level-and-senior-developer/",
        published: true,
        deadline: true
    },

    {
        id: "4",
        title: "Virtual Dreamin 2020 (First SalesForce Event)",
        talk_theme: "Differences Between a Junior, Mid-Level, and Senior Developer",
        location: "Online - YouTube",
        dataTime: 'April 22, 2020 - 06:30 PM – 06:50 PM EST',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704127508/vD.jpg",
        short_description: `Years of programming experience does not solely have to mean you’re a junior, mid-level or senior developer. There are junior developers who are way older than in years of experience than a senior developer. The difference, how to identify a developer who falls within those levels and steps to take to become a get to the next level is what I’ll be talking about in this talk. At the end of the talk, junior, mid-level, and senior developers will be able to determine if they are really what they claim to be and what to do to help them get to that level.`,
        events_url: "https://virtualdreamin.com/differences-between-a-junior-mid-level-and-senior-developer/",
        published: true,
        deadline: true
    },

    {
        id: "6",
        title: "unStack 2-day React Workshop 2020",
        talk_theme: "Hands-on - Deploy a React app using Zeit",
        location: "Lagos - Nigeria",
        dataTime: 'March 21st, 2020 - 01.00 – 01.30 WAT',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704130128/Screenshot_2024-01-01_at_5.27.19_PM.png",
        short_description: `In this talk, I to walked people through how they can deploy their Reactjs to Zeit`,
        events_url: "https://www.linkedin.com/feed/update/urn:li:activity:6639075808721932288/",
        published: true,
        deadline: true
    },
    

    {
        id: "7",
        title: "BIZFEST - Google Business Group(GBD) Abeokuta 2019",
        talk_theme: "Keynote speaker: Android vs Business",
        location: "Abeokuta - Nigeria",
        dataTime: 'November 16, 2019 - 09:00 AM – 09:30 AM WAT',
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1704130128/Screenshot_2024-01-01_at_5.27.52_PM.png",
        short_description: `Android, being one of the most widely used mobile operating systems globally, offers a range of tools and features that can be customized to suit different business needs. The talk points out how businesses can integrate this technology into their business, making life easy for their customers.`,
        events_url: "https://twitter.com/GBGAbeokuta/status/1202655201360056320",
        published: true,
        deadline: true
    },

   
    // {
    //     id: "2",
    //     title: "IJS London 2020",
    //     talk_theme: "Domain driven Design",
    //     image: "https://i.ibb.co/YpX6xkL/new800.png",
    //     short_description: "First generation blockchains like Bitcoin, Ethereum and Solana are saddled to the tasks of carrying out consensus, settlement, execution and data availability on the same layer hence...",
    //     events_url: "",
    //     published: true
    // },
];
