/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ homepage }) => {
    const { full_name, career, profile_picture, linkedin, twitter, instagram, github, youtube } = homepage;
    return (
        <div className="col-lg-3">
            <aside className="side-bar sticky-top">
                
                <div className="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="dlab-box m-b30 dlab-team7">
                        <div className="dlab-media">
                           
                                <img alt="" src={profile_picture} />
                          
                        </div>
                        <div className="dlab-info">
                            <h4 className="dlab-title"><Link to="/about">{full_name}</Link></h4>
                            <span className="dlab-position text-black">{career}</span>
                            <ul className="dlab-social-icon">
                                <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-youtube" href={youtube}></a></li>
                                <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-github" href={github}></a></li>
                                <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-twitter" href={twitter} rel="noreferrer"></a></li>
                                <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-linkedin" href={linkedin}></a></li>
                                <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-instagram" href={instagram}></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <div className="widget widget-newslatter">
                    <h5 className="widget-title style-1">Newsletter</h5>
                    <div className="news-box">
                        <p>Good things comes to those who subscribe, stay in the loop. Enter your e-mail.</p>
                        <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                            <div className="dzSubscribeMsg"></div>
                            <div className="input-group">
                                <input name="dzEmail" required="required" type="email" className="form-control" placeholder="Your Email" />
                                <button name="submit" value="Submit" type="submit" className="site-button w-100 radius-no">Subscribe Now</button>
                            </div>
                        </form>
                    </div>
                </div> */}
            </aside>
        </div>
    );
}

export default Sidebar;