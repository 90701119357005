import React from 'react';
import Counter from './counter';

const CounterSection2 = ({backgroundImage}) => {
    return ( 
    <div className="section-full content-inner overlay-black-dark bg-img-fix" style={{ backgroundImage: "url(" + "https://images.unsplash.com/photo-1551911729-aeb5b569c307?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80" + ")" }}>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="counter-style-1 text-center text-white">
                        <div className="">
                            <Counter count={400}/>+
                        </div>
                        <span className="counter-text">Mentorship Sessions Hours</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="counter-style-1 text-center text-white">
                        <div className="">
                            <Counter count={120}/>+
                        </div>
                        <span className="counter-text">Students/Mentees</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="counter-style-1 text-center text-white">
                        <div className="">
                            <Counter count={190}/>+
                        </div>
                        <span className="counter-text">Reviews</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="counter-style-1 text-center text-white">
                        <div className="">
                          <h1 style={{ fontSize: 60 }} className='className="dlab-title counter"'><b>4.9</b></h1>
                        </div>
                        <span className="counter-text">Average Rating</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
 
export default CounterSection2;