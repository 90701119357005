export const aboutIntro = {
    aboutIntro: ""
};

export const aboutReviews = [
    {
        full_name: "Onyekachi EzeokeOnyekachi Ezeoke",
        image: "https://media.licdn.com/dms/image/C4D03AQEHXK3cNQFKsg/profile-displayphoto-shrink_100_100/0/1645431032016?e=1676505600&v=beta&t=ZS3mA3Q1ArG4cjsSTzq9ap4hqw8dPdorAMzzCCTpFDM",
        body: "I mentored Anayo earlier in his career and I have come to know him as someone very reliable and passionate about solving problems and building a great career as a software engineer. I got connected to him through facebook where he expressed his intention to me about building a career as a software engineer. We worked together in achieving his ambition. This paid off at the end and got him into Andela which is a very competitive fellowship program. He is very honest and dedicated, give him the opportunity to prove himself and his abilities.",
        role: "Senior IOS developer"
    },
    {
        full_name: "Heba Saleh",
        image: "https://media.licdn.com/dms/image/C5603AQEyzCF9iAeF1w/profile-displayphoto-shrink_100_100/0/1567196139811?e=1676505600&v=beta&t=eQ6TzuuPFxR8ZshLgxFclkZ41cs_PdtWHLV5gLvFNM4",
        body: "Enthusiastic is the phrase that comes to mind when I think about Anayo I’ve had the pleasure of knowing Anayo for two months, during my mentorship his communication skills. Above all, I was impressed with  Anayo’s ability to communicate and express his ideas clearly. And, of course, his technical experience is pushing him to do more. Anayo would be a true asset for any positions requiring technical and communication and comes with my heartfelt recommendation.”",
        role: "Engineering Manager at Andela"
    },

    {
        full_name: "Andrew Twijukye",
        image: "https://media.licdn.com/dms/image/C4E03AQEUwXAWNu_Hmw/profile-displayphoto-shrink_100_100/0/1541408535830?e=1676505600&v=beta&t=P6-uvQzrZpvPunN4FRpIAdXpcGJEKmhZ48tuBCcQA0o",
        body: `Now, I love Android development, but when I saw that not many opportunities were coming in for it at Andela, I decided to join a React Native product to make sure my mobile skills were diversified. Anayo was my onboarding buddy on this product and he could not have made my transition to a javascript product any easier. He is an excellent Javascript developer with a thirst for understanding. He would be a valuable addition to any software development team.`,
        role: "Software Engineer at Alation"
    },
    {
        full_name: "Sulaiman Sanusi",
        image: "https://media.licdn.com/dms/image/C4D03AQHEUybOCCbSIg/profile-displayphoto-shrink_100_100/0/1616326283024?e=1676505600&v=beta&t=7oudnlTgkZVKLbFTAn5UrqkIvAuDKVOxqRaY6soSTuM",
        body: `A brilliant individual with an exceptional technical skillset and a good team player I enjoyed working with Anayo because he is very dependable `,
        role: "Senior Software Engineer | blockchain enthusiast"
    },

    {
        full_name: "Clet Mwunguzi",
        image: "https://media.licdn.com/dms/image/C4D03AQHQe-sPerNcQA/profile-displayphoto-shrink_100_100/0/1574797196713?e=1676505600&v=beta&t=yKLTLdHnh5ZthvSy7Gx8s-K_2OwUAwKbqN9VyVNig_I",
        body: `Anayo Oleru is a well-rounded software engineer with good communication skills and listening skills. I’ve always been surprised by his passion and integrity that he demonstrated.

        I worked with Anayo as Software engineers at Andela to build and create a mobile application for booking meeting rooms.
        
        He not only solves problems and adds value to it, but he also aim to fix them forever. Working with Anayo on the same project was memorable because of the way he approached problems, his will to try out different ways before coming up with a creative solution that would permanently fix the problem. And this is what made our product successful throughout the whole year. 
        
        We would not have achieved anything without him. 
        
        I’m happy about the work Anayo has done to our product, and I recommend him to others looking for not only a software engineer but also someone who is a great addition to the team.`,
        role: "Software Engineer"
    },

    {
        full_name: "Prince Sengayire",
        image: "https://media.licdn.com/dms/image/C4D03AQFPoR9n48g75A/profile-displayphoto-shrink_100_100/0/1623656064938?e=1676505600&v=beta&t=WTmYX4AlLtv5enhDTIwKSZVcS-9nnhHzXvL98i5026o",
        body: `I  am lucky to have worked with Anayo Oleru, a teammate who is not only interested in his daily work and deliverables but also a person who really strive to help others to make their work perfect and unblock them.

        Anayo has worked with me as a software developer on Companion app product owned by Andela. Anayo was a collaborative person to everyone, also he was able to adapt to a new surrounding environment easily. I am saying this because I have worked with him on a collaborative task and he really helped me considerably to complete my work. Due to his eagerness of helping people, we were able to deliver our work in shot time than expected. 
       
       Anayo's skills, creativity, and professionalism in software development career made him a valuable member of our team. I am sure he will be an asset to any organization that he will be joining.
       
       I happily recommend him and do so in-person to everyone whenever I will have the opportunity.`,
        role: "Frontend Developer"
    },

    {
        full_name: "Fiston Kabalisa",
        image: "https://media.licdn.com/dms/image/C4D03AQEP6CrTFrLIwA/profile-displayphoto-shrink_100_100/0/1626764407329?e=1676505600&v=beta&t=DBE3ANAERmf9TBCtEBW_X2bxOGgXD5AxYSE9QYjdAFw",
        body: `Anayo is a talented and creative software developer. This is portrayed through the excellent and efficient work he delivers. Anayo was my teammate on a mobile app product where we were both software developers working on a project using React Native.
        The workflow on our team was that each team member was given a task and had to come up with a good algorithm to implement the task. Anayo’s tasks were always satisfying to our team and our users due to the quality of his code and his creative algorithms which increased the user experience of our product.
        In addition to his technical skills, Anayo is also a good teammate because he provided help and ideas while needed on our team. I enjoyed having Anayo as a teammate and I believe he would add value to any team he joins. `,
        role: "Software Engineer"
    },

    {
        full_name: "Roghashin Timbiti",
        image: "https://media.licdn.com/dms/image/C4D03AQHyp0EqZ732VQ/profile-displayphoto-shrink_100_100/0/1589042670657?e=1676505600&v=beta&t=JtvsXOIlt1st6qYLwTSNV0PJxzeJzZF3lAxeURgM15A",
        body: `I have had a chance to work with Anayo and it is with pleasure to recommend him.
        During the period we worked together, Anayo proved to be a dependable and hard-working team-mate with problem-solving and technical skills. I was always impressed by Anayo’s ability to complete the work assigned to him on time. He not only met deadlines but was also in a position to explain his implementation with clarity and certainty. Anayo always took his work seriously and is dedicated to delivering quality work.`,
        role: "Software Engineer"
    },

    {
        full_name: "MUSIGWA Pacifique",
        image: "https://media.licdn.com/dms/image/D4D35AQGcZNQ6SDnZHQ/profile-framedphoto-shrink_100_100/0/1666288958333?e=1671710400&v=beta&t=Tl6eiH0W8BL2mVa_frBmZoVbgo5sD2kxFTUqnxb-R9I",
        body: `I highly recommend Anayo Oleru to be a good engineer for his ability to proactively communicate any changes, updates or/and any other concerns in the teams which speed up the development and make the working environment better.

        One of the main reasons that Anayo and I have been successful co-workers
        on almost every project that the company has been entrusted with is; his
        ability to delve into details. In his role as a software engineer, he came across many challenges; his reason for success in meeting all these challenges head-on is his ability to chart out his limitations. He is never shy of asking for help and takes constructive criticism quite well.
        
        During all the times that I have worked with Anayo, I have had the opportunity
        to observe his interpersonal style – he is a pleasant individual who believes 
        in positive motivation and his team is never dissatisfied by his collaboration 
        and initiatives.`,
        role: "Software Engineer"
    },

    {
        full_name: "Yves Iraguha",
        image: "https://media.licdn.com/dms/image/C5603AQGPPAHPvkXsPQ/profile-displayphoto-shrink_100_100/0/1547493134241?e=1676505600&v=beta&t=TrhUjuV6Yb5wCVezD0vgrjkfu1L5mMq5ajcQi7iJe48",
        body: `Anayo is a passionate associate engineer. He has worked with me as an apprentice at Andela for 4monhts during which we collaborated to build CompanionApp, a cross-platform mobile application built using JavaScript/ReactNative technology. 

        He was an engine on our team when it comes to training DialogFlow bot and making it more experts, and it’s thanks to him that he helped us to provide more natural conversational experience through CompanionApp to users.
        
        As a team member, he focuses on what needs to be done and encourages everyone to do his best. I’m happy with the work Anayo did as an associate engineer, and I give him my full recommendation. `,
        role: "Software Engineer at Fiskaly"
    },
];
