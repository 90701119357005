import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from '../layout/header';
import Footer from '../layout/footer';
import {
	eventsIntro,
	eventsIntroNew,
	events
} from '../../contents';

class Events extends Component {


	render() {
		return (
			<>
				<Helmet>
					<title>{`Anayo Oleru | Speaking Events`}</title>
					<meta
						name="description"
						content="Anayo Samson Oleru - Software Solution Architect"
					/>
					<meta
						name="keywords"
						content="System design AWS, Azure, Google Cloud, Code Mentor, Coding Help, Pair Programming, Software Expert, Top Developer, Freelance Job"
					/>
				</Helmet>
				<Header active="events" />
				<div className="page-content bg-white">
					<div className="bg-white">
						<div className="section-full bg-gray content-inner">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 text-center">
										<div className=" clearfix">
											<h4>{eventsIntroNew.title}</h4>
										</div>
										<div className="dlab-separator-outer ">
											<div className="dlab-separator bg-primary style-icon text-white radius-xl"><i className="fa fa-leaf"></i></div>
										</div>
										<p>{eventsIntroNew.description}</p>
									</div>
									<div className="section-content"></div>
								</div>
							</div>
						</div>


						{events.map((event, index) => {
							return (
								<div className="section-full bg-white content-inner">

									{!event.deadline && <div className="container">
										<div className="row">
											<div className="col-lg-12">
												<div className=" clearfix" style={{ marginBottom: 25 }}>
													<img style={{
														width: "100%",
														height: 200,
														objectFit: "cover"
													}} src={event.image} alt={event.title} />
	<br/>
												</div>
													<h4>{event.title}</h4>
												<div className="text-black clearfix">
													<span><b>Talk Theme:</b> {event.talk_theme}</span>
													<br />
													<span><b>Location:</b> {event.location}</span>
													<br />
													<span><b>Date and Time:</b> {event.dataTime}</span>
												</div>
												<div className="dlab-separator-outer ">
													<div className="dlab-separator style-icon"><i className="fa fa-leaf"></i></div>
												</div>
												<p className='text-black'>{event.short_description}</p>
											</div>
											<div className="section-content">
												<a className='site-button btnhover21 black m-r10 m-b10 ' href={event.events_url} target="_blank" rel="noreferrer">Attend Event</a>
											</div>
										</div>
									</div>}
								</div>
							)
						})}
					</div>
				</div>

				<div className="page-content bg-white">
					<div className="bg-white">
						<div className="section-full bg-gray content-inner">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 text-center">
										<div className=" clearfix">
											<h4>{eventsIntro.title}</h4>
										</div>
										<div className="dlab-separator-outer ">
											<div className="dlab-separator bg-primary style-icon text-white radius-xl"><i className="fa fa-leaf"></i></div>
										</div>
										<p>{eventsIntro.description}</p>
									</div>
									<div className="section-content"></div>
								</div>
							</div>
						</div>


						{events.map((event, index) => {
							return (
								<div className="section-full bg-white content-inner">
									{event.deadline && <div className="container">
										<div className="row">
											<div className="col-lg-12">
												<div className=" clearfix" style={{ marginBottom: 25 }}>
													<img style={{
														width: "100%",
														height: 200,
														objectFit: "cover"
													}} src={event.image} alt={event.title} />

												</div>
													<h4>{event.title}</h4>
												<div className="text-black clearfix">
													<span><b>Talk Theme:</b> {event.talk_theme}</span>
													<br />
													<span><b>Location:</b> {event.location}</span>
													<br />
													<span><b>Date and Time:</b> {event.dataTime}</span>
												</div>
												<div className="dlab-separator-outer ">
													<div className="dlab-separator style-icon"><i className="fa fa-leaf"></i></div>
												</div>
												<p className='text-black'>{event.short_description}</p>
											</div>
											<div className="section-content">
												<a className='site-button btnhover21 black m-r10 m-b10 ' href={event.events_url} target="_blank" rel="noreferrer">View Event</a>
											</div>
										</div>
									</div>}
								</div>
							)
						})}
					</div>
				</div>
				<Footer />
			</>
		);
	};
};

export default Events;
