import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Sidebar from '../element/sidebar';
import { Helmet } from "react-helmet";

import {
    homepage,
    articles
} from "../../contents";

const HomePage = () => {
    const [pageCount, setPageCount] = React.useState(4);

    const { name, short_description } = homepage;
    return (
        <>
            <Helmet>
                <title>Anayo Oleru - Articles</title>
                <meta
                    name="description"
                    content="Read articles on Cloud computing, Web development, mobile development and all things tech."
                />
                <meta
                    name="keywords"
                    content="Articles, tutorials, videos, web development, mobile development, cloud computing, mentorship, open-source, local event, anayo, oleru, samson"
                />
            </Helmet>
            <Header active="articles" />
            <div className="page-content bg-white">
                <div className="content-area">
                    <div className="container">

                        <div className="section-head text-black text-center">
                            <h1 className="title">{name}</h1>
                            <p className="section-head text-black text-center">{short_description}</p>

                            {/* <div className="search-bx style-1 col-lg-12">
                                <form role="search" method="post">
                                    <div className="input-group">
                                        <input name="text" className="form-control" placeholder="Search for posts" type="text" />
                                        <span className="input-group-btn">
                                            <button type="submit" className="fa fa-search text-primary"></button>
                                        </span>
                                    </div>
                                </form>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-lg-9 col-md-7">
                                <div id="masonry" className="dlab-blog-grid-2">
                                    <div className="row">
                                        {articles.length === 0 && <p className='text-center'>There are no articles published yet, please check back later</p>}

                                        {articles.slice(0, pageCount).map((article, index) => {
                                            return (
                                                <div className="post card-container text-black col-lg-6 col-md-12 col-sm-12" id={index}>
                                                    <div className="blog-post blog-grid blog-rounded blog-effect1">
                                                        <div className="dlab-post-media dlab-img-effect">
                                                            <Link to={article.url}><img style={{
                                                                height: 250
                                                            }} src={article.image} alt="" /></Link>
                                                        </div>
                                                        <div className="dlab-info p-a20 border-1">
                                                            <div className="dlab-post-title">
                                                                <h4 className="post-title"><Link to={article.url}>{article.title}</Link></h4>
                                                            </div>
                                                            <div className="text-black">
                                                                <p>{article.short_description.slice(0, 45) + "..."}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {articles.length < pageCount ? null : (
                                        <div className="pagination-bx clearfix m-b30 text-center col-md-12">
                                            <ul className="pagination">

                                                <li className="active next"><Link onClick={() => {
                                                    setPageCount(pageCount + 4);
                                                }}>View More <i className="ti-arrow-down"></i></Link></li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Sidebar homepage={homepage} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default HomePage;
