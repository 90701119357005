import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { useMediaQuery } from 'react-responsive';
import { header, homepage, footer } from '../../contents';

// const isBigScreen = () => {  useMediaQuery({ minWidth: 991 })}
// const isTabletOrMobile = () => useMediaQuery({ maxWidth: 1224 })

const Example = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 991 })
    // const isBigScreen = useMediaQuery({ minWidth: 1824 })
    // const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    // const isPortrait = useMediaQuery({ orientation: 'portrait' })
    // const isRetina = useMediaQuery({ minResolution: '2dppx' })

    return
}
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width

}
class Header4 extends Component {


    componentDidMount() {




        // sidebar open/close
        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');

        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');

            if (active) {
                current.classList.remove('open')
                //console.log("active")
            } else {
                current.classList.add('open');
                //console.log("close")
            }
        };

    }



    render() {
        const { title, home, courses, books, mentorship, open_source, events, about, simpleTech, asoAcademy } = header;
        const { linkedin, twitter, instagram, youtube, github } = homepage;
        return (
            <>
                <Sticky innerZ={999} enabled={true}>
                    <header className="site-header mo-left header navstyle2">
                        <div className="sticky-header main-bar-wraper header-curve navbar-expand-lg">
                            <div className="main-bar clearfix">
                                <div className="container clearfix">

                                    <div className="logo-header mostion mt-3">
                                        {getWindowDimensions() > 991 ? (
                                            <ul className="nav navbar-nav">
                                                <li className={`${this.props.active === "home" ? " has-mega-menu " : "has-mega-menu"}`}> <Link to="/"><h4 className='text-dark'>{title}</h4></Link>
                                                </li>
                                            </ul>
                                        ) : (<ul className="nav navbar-nav">
                                            <li className={`${this.props.active === "home" ? " has-mega-menu " : "has-mega-menu"}`}> <Link to="/"><h4 className='text-dark'>{title}</h4></Link>
                                            </li>
                                        </ul>)}
                                    </div>

                                    <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>

                                    <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                        <div className="logo-header d-md-block d-lg-none">
                                            <Link to="/"><h2>{title}</h2></Link>
                                        </div>
                                        <ul className="nav navbar-nav">
                                            <li className={`${this.props.active === "home" ? "active has-mega-menu homedemo" : "has-mega-menu"}`}> <Link to="/">{home}</Link>
                                            </li>
                                            <li className={`${this.props.active === "academy" ? "active has-mega-menu homedemo" : "has-mega-menu"}`}>
                                                <Link to="/academy">{asoAcademy}</Link>
                                            </li>
                                            <li className={`${this.props.active === "articles" ? "active has-mega-menu homedemo" : "has-mega-menu"}`}>
                                                <Link to={{ pathname: "https://www.medium.com/@anayooleru" }} target="_blank">{about}</Link>
                                            </li>
                                            <li className={`${this.props.active === "mentorship" ? "active has-mega-menu homedemo" : "has-mega-menu"}`}>
                                                <Link to={{ pathname: "https://www.codementor.io/@anayooleru274" }} target="_blank">{mentorship}</Link>
                                            </li>
                                            {/* <li className={`${this.props.active === "open-source" ? "active has-mega-menu homedemo" : "has-mega-menu"}`}> <Link to={'/open-source'}>{open_source}</Link>
                                            </li> */}
                                            <li className={`${this.props.active === "events" ? "active has-mega-menu homedemo" : "has-mega-menu"}`}>
                                                <Link to={{ pathname: "/events" }} >{events}</Link>
                                            </li>
                                           

                                        </ul>
                                        <div className="dlab-social-icon ind-social">
                                            <ul>
                                                <li><a target={"_blank"} className="site-button-link twitter fa fa-youtube" href={youtube} rel="noreferrer"></a></li>
                                                <li><a target={"_blank"} className="site-button-link linkedin fa fa-github" href={github}></a></li>
                                                <li><a target={"_blank"} className="site-button-link twitter fa fa-twitter" href={twitter} rel="noreferrer"></a></li>
                                                <li><a target={"_blank"} className="site-button-link linkedin fa fa-linkedin" href={linkedin}></a></li>
                                                <li><a target={"_blank"} className="site-button-link instagram fa fa-instagram" href={instagram}></a></li>
                                            </ul>
                                            <p>{footer.title}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </Sticky>
            </>
        )
    }
}
export default Header4;