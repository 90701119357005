export const booksIntro = {
    title: "Books",
    description: "Gain fresh perspective on essential and emerging technologies - from cloud, mobile and web development - and the related challenges. Put these technology books on your reading list for 2022"
};

export const books = [
    // {
    //     id: "1",
    //     title: "Understanding The Modular Blockchain Infrastructure",
    //     image: "https://i.ibb.co/YpX6xkL/new800.png",
    //     short_description: "First generation blockchains like Bitcoin, Ethereum and Solana are saddled to the tasks of carrying out consensus, settlement, execution and data availability on the same layer hence...",
    //     amazon_url: "",
    //     gumroad_url: "",
    //     published: true
    // },
    // {
    //     id: "2",
    //     title: "Understanding The Modular Blockchain Infrastructure 2",
    //     image: "https://i.ibb.co/YpX6xkL/new800.png",
    //     short_description: "First generation blockchains like Bitcoin, Ethereum and Solana are saddled to the tasks of carrying out consensus, settlement, execution and data availability on the same layer hence...",
    //     amazon_url: "",
    //     gumroad_url: "",
    //     published: true
    // },
];
