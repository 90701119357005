export const articles = [
    {
        id: 1,
        title: "Understand Event Sourcing Pattern vs Event Driven Architecture",
        slug: "understand-event-sourcing-pattern-vs-event-driven-architecture",
        url: "/articles/understand-event-sourcing-pattern-vs-event-driven-architecture",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673467099/video-thumb-nail.jpg",
        short_description: `During my talks with several developers, mentees, engineering enthusiasts, and colleagues, I tasted that there are still some unknowns about what each of them does, and how they relate to each other, some see them as hard to understand, and you know truly it can get complicated, for some they see them as the same, others might actually be using this patterns but don’t know the terms for it or the name they call them. In my video today, I am going to debunk them, break them up, I am going to try so hard to explain them clearly and clear up some mysteries about them.`,
        file_name: "understand_event_sourcing_pattern_vs_event_driven_architecture.md",
        tags: ["video", "architecture", "domain-driven", "event-driven", "sourcing-pattern"],
        published: true,
    },
    {
        id: 1,
        title: "Modifying an existing sequelize migration",
        slug: "modifying-an-existing-sequelize-migration",
        url: "/articles/modifying-an-existing-sequelize-migration",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1671694056/modifying-sequelize-migration.jpg",
        short_description: "If you've just started using sequelize and sequelize CLI in development, you definitely had frequent addition and deletions of columns. And yeah, all these times you directly modified the migrations file, dropped all migrations and re-ran migrations again, so that your changes will reflect.",
        file_name: "modifying_an_existing_sequelize_migration.md",
        tags: ["article", "sequelize", "postgres", "modifications", "sequelizecli"],
        published: true,
    },
    {
        id: 2,
        title: "Setup React-Native Font: The Easy Way",
        slug: "setup-react-native-font-the-easy-way",
        url: "/articles/setup-react-native-font-the-easy-way",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1671696345/Setup-react-fonts.jpg",
        short_description: `I’ve seen a lot of developers struggle with this part when developing react-native applications. And honestly, I tell you at first it seems like a hard nut to crack, but it’s one of the easiest things to do on react-native development. And it’s also a core aspect, you will definitely need to add an external font in any application, so you must learn it and add that to your wealth of knowledge.`,
        file_name: "setup_react_native_font_the_easy_way.md",
        tags: ["fonts", "react-native", "react-native development", "android", "ios"],
        published: true,
    },
    {
        id: 2,
        title: "Remove React-Native Font: The Easy Way",
        slug: "remove-react-native-font-the-easy-way",
        url: "/articles/remove-react-native-font-the-easy-way",
        image: "https://miro.medium.com/max/1400/1*TOO5nQoQETyIhLbZmZrBDw.webp",
        short_description: `The last article I wrote was how to Setup React-Native Font: The Easy Way.
        But there will definitely be a time when you’ll need to remove the fonts`,
        file_name: "remove_react_native_font_the_easy_way.md",
        tags: ["fonts", "react-native", "react-native development", "android", "ios"],
        published: true,
    },
    {
        id: 3,
        title: "Awesome code guidelines",
        slug: "awesome-code-guidelines",
        url: "/articles/awesome-code-guidelines",
        image: "https://miro.medium.com/max/464/1*PgzqSS-ls-l7Gd-OhqOTJg.webp",
        short_description: `In software engineering, it is very important to organize your work, structure efforts and prioritize the task. This will help you achieve better results.`,
        file_name: "awesome_code_guidelines.md",
        tags: ["code", "guidelines", "good code rules", "performance improvement"],
        published: true,
    },
    {
        id: 3,
        title: "Module.createRequire is not a function",
        slug: "modulecreaterequire-is-not-a-function",
        url: "/articles/modulecreaterequire-is-not-a-function",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673708537/Purple_Creative_Livestream_YouTube_Thumbnail.jpg",
        short_description: `In my react project's root I have a GitLab-ci.yml file that automatically runs whenever I push a commit to the server. `,
        file_name: "modulecreaterequire_is_not_a_function.md",
        tags: ["react", "gitlab", "deployment", "nodeversion"],
        published: true,
    },
    {
        id: 4,
        title: "No Permission Handler Detected (IOS)",
        slug: "no-permission-handler-detected-ios",
        url: "/articles/no-permission-handler-detected-ios",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673708970/Purple_1.jpg",
        short_description: `In my react project's root I have a GitLab-ci.yml file that automatically runs whenever I push a commit to the server. `,
        file_name: "no_permission_handler_detected_ios.md",
        tags: ["ios", "xcode", "permissionhandler"],
        published: true,
    },
    {
        id: 5,
        title: "java.lang.NoClassDefFoundError: <clinit> failed for class com.facebook.flipper.android...",
        slug: "javalangnoclassdeffounderror-failed-for-class-comfacebookflipperandroideventbase",
        url: "/articles/javalangnoclassdeffounderror-failed-for-class-comfacebookflipperandroideventbase",
        image: "https://res.cloudinary.com/dvfr0z8wr/image/upload/v1673708969/Purple2.jpg",
        short_description: `In my react project's root I have a GitLab-ci.yml file that automatically runs whenever I push a commit to the server. `,
        file_name: "javalangnoclassdeffounderror_failed_for_class_comfacebookflipperandroideventbase.md",
        tags: ["reactnative", "androidstudio", "flipper", "unknownpackage"],
        published: true,
    },
    {
        id: 5,
        title: "How to change your Git’s Remote URL",
        slug: "how-to-change-your-git-remote-url",
        url: "/articles/how-to-change-your-git-remote-url",
        image: "https://sparkbox.com/uploads/featured_images/m-thompson_20-08.png",
        short_description: `Let’s go straight to the point, there’s no need going about what and how the remote git URL is structured if you want that, you can find that here:`,
        file_name: "how_to_change_your_git_remote_url.md",
        tags: ["git", "url", "github", "bitbucket"],
        published: true,
    },
    {
        id: 5,
        title: "Easy steps to setup Code Coverage with Coveralls",
        slug: "easy-steps-to-setup-code-coverage-coveralls",
        url: "/articles/easy-steps-to-setup-code-coverage-coveralls",
        image: "https://miro.medium.com/max/1000/1*EdzSYJ6RGPgWt11pcPVJZQ.webp",
        short_description: `When starting with Continuous Integration and Continuous Deployment, code coverage is one of the tools that improve your workflow significantly.`,
        file_name: "easy_steps_to_setup_code_coverage_coveralls.md",
        tags: ["javascript", "coveralls", "bugs", "tdd", "node"],
        published: true,
    },
];
