import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Academy from './pages/academy';

// used pages
import HomePage from './pages/homepage';
import Courses from './pages/courses';
import Books from './pages/books';
import Mentorship from './pages/mentorship';
import OpenSource from './pages/open-source';
import Events from './pages/events';
import About from './pages/about';
import TermsAndConditions from './pages/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import Faq from './pages/faq';
import BlogRead from './pages/blog-read';
import CourseRead from './pages/course-read';
import CourseReadSingle from './pages/course-read-single';

// test pages
import Index15 from './pages/index';


class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={About} />
                        <Route path='/articles' component={HomePage} />
                        <Route path='/courses' exact component={Courses} />
                        <Route path='/books' component={Books} />
                        <Route path='/mentorship' component={Mentorship} />
                        <Route path='/open-source' component={OpenSource} />
                        <Route path='/events' component={Events} />

                        <Route path='/terms-and-conditions' component={TermsAndConditions} />
                        <Route path='/privacy-policy' component={PrivacyPolicy} />
                        <Route path='/faq' component={Faq} />
                        <Route path='/articles/:slug' component={BlogRead} />
                        <Route path='/course/start/:slug' component={CourseRead} />
                        <Route path='/courses/:slug' exact component={CourseReadSingle} />
                        <Route path='/academy' exact component={Academy} />
                    </Switch>
                </div>
            </BrowserRouter>

        )
    }
}

export default Router;