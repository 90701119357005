export const reviews = [
    {
        full_name: "Patrizia Berchi",
        image: "https://media.licdn.com/dms/image/C4D03AQGivTDzdsBqzQ/profile-displayphoto-shrink_100_100/0/1588936716230?e=1676505600&v=beta&t=KcKxgSehJnppZnwmi6ycMEtFvgjcDTgY0qMNQZxZrns",
        body: "Anayo was really nice and helped me solve some node.js issue fast",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "NodeJS",
        role: "Web developer"
    },
    {
        full_name: "Ashley Turnbull",
        image: "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
        body: "Anayo was very knowledgeable in React and was able to help me make changes to an existing project that solved my problem, while also helping me understand more about react as a whole!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "ReactJS",
        role: ""
    },
    {
        full_name: "A C",
        image: "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
        body: "Anayo is a great instructor! he acknowledges students concerns and ensures doubts are always 100% clear!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "React-native",
        role: ""
    },
    {
        full_name: "Kath Ren",
        image: "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
        body: "Anayo was very helpful and resourceful! I have learned a lot today from him. He solved all the problems I had with my codes, which was amazing. When I run into a trouble again with the upcoming features, I will definitely ask for his help. Thank you again!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "Firebase/auth, React native",
        role: ""
    },
    {
        full_name: "SantaRedux",
        image: "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
        body: "Anayo is very knowledgeable and helped me immeasurably!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "ReactJS, Redux",
        role: ""
    },
    {
        full_name: "Amanda Mac",
        image: "https://media.licdn.com/dms/image/C4E03AQEt6POu7yC_JQ/profile-displayphoto-shrink_100_100/0/1596581425441?e=1676505600&v=beta&t=GR_CVYDynak2YUMeBIjDIUr6oUwfY5d_w7gVhTv3k0Y",
        body: "Anayo is a great mentor! He helps you face the challenge head on bugs or functionality! Thank you so much! Look forward to working with him a lot more in the very near future!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "ReactJS",
        role: "Chief Executive Officer at TelePsycRX LLC"
    },

    {
        full_name: "Antony Paul",
        image: "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
        body: "Good mentor had a methodic approach to solving the problem, the best part he lets you do it and walks you through the process once he finds the issue! Very patient and resourceful, will use again in the future!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "AWS, JavaScript",
        role: ""
    },
    {
        full_name: "Tory Hoke",
        image: "https://media.licdn.com/dms/image/C4E03AQFa257KY0ZRaw/profile-displayphoto-shrink_200_200/0/1517079359503?e=1676505600&v=beta&t=lp8l9K_3J_IQxwPlZQjsBeuPgzG4xJKq1pB2NxTK7cs",
        body: "Gave me ideas to break through a sticky memory leak issue. Thank you, Anayo!",
        link: "https://www.codementor.io/@anayooleru274",
        designation: "ReactJS",
        role: "Senior Software Developer"
    },
];

