import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { Helmet } from "react-helmet";
import Testmonial5 from '../element/testmonial4';
import { homepage, aboutReviews } from '../../contents';

class About extends Component {

    render() {
        const { full_name, career, profile_picture, linkedin, twitter, instagram, youtube, github } = homepage;
        return (
            <>
                <Helmet>
                    <title>{`${full_name} | ${career}`}</title>
                    <meta
                        name="description"
                        content="Hi, I am Anayo Samson Oleru, a dynamic Software Engineer and Software Solution Architect with over five years of hands-on experience in crafting software solutions for diverse clients across various industries. My career blends technical expertise with a keen business sense to drive organizational success. Throughout my journey, I've thrived in collaborative team environments, where we've tackled complex challenges, enabling companies to not only overcome hurdles but also expand their market presence.

                        My proficiency spans the entire product life-cycle, encompassing the development of mobile and web applications, server solutions, and the architecture of scalable cloud-based systems. I excel in every phase, from concept inception to production, scaling, and seamless business integration. My unwavering passion for teamwork has allowed me to foster a positive and productive work environment with colleagues.
                        
                        Let's join forces to transform your tech vision into reality!"
                    />
                    <meta
                        name="keywords"
                        content="AWS, Code Mentor, Coding Help, Pair Programming, Software Expert, Top Developer, Freelance Job"
                    />
                </Helmet>
                <Header active="about" />
                <div className="page-content bg-white">
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <h1 className="title text-center"><b>{career}</b></h1>
                                    <div className="col-lg-8 col-md-12 m-b30">
                                        <div className="our-story">


                                            <p style={{ fontSize: 20 }}>Hey, I am a <i>Software Solution Architect</i> and <i>Software Engineer</i> with years of professional experience in developing mobile and web applications, server solutions, and designing scalable software systems.
                                            </p>
                                            <p style={{ fontSize: 20 }}>

                                                I have lead teams through entire product lifecycle, from concept to production and production to scaling.
                                            <br/>
                                            <br/>
                                                Throughout my journey, I've thrived in collaborative team environments, where I've tackled complex challenges, enabling companies to not only overcome hurdles but also expand their market presence.
                                            </p>

                                            <p style={{ fontSize: 20 }}>
                                                My unwavering passion for teamwork has allowed me to foster a positive and productive work environment with colleagues.
                                            </p>

                                            <p style={{ fontSize: 20 }}>
                                                Let's join forces to transform your tech vision into reality! <a style={{ color: 'blue' }} target='_blank' href='https://www.linkedin.com/in/anayooleru/'>Get in touch with me.</a>
                                            </p>

                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12 m-b30 our-story-thum">
                                        <div className="dlab-box fly-box">
                                            <div className="dlab-media">
                                                <img src={profile_picture} alt="Anayo" />
                                            </div>
                                            <div className="dlab-info p-a2 p-t4">
                                                <div className="bg-primary skew-content-box">
                                                    <ul className="dlab-social-icon">
                                                        <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-youtube" href={youtube} rel="noreferrer"></a></li>
                                                        <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-github" href={github}></a></li>

                                                        <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-twitter" href={twitter} rel="noreferrer"></a></li>
                                                        <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-linkedin" href={linkedin}></a></li>
                                                        <li><a target={"_blank"} className="site-button circle-sm gray-light fa fa-instagram" href={instagram}></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};

export default About;
