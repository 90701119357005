export const header = {
    title: "Anayo Oleru",
    home: "Home",
    courses: "Courses",
    books: "Books",
    mentorship: "Mentorship",
    open_source: "Open Source",
    events: "Events",
    about: "Articles",
    simpleTech: "Simple Tech",
    asoAcademy: "ASO Academy"
};