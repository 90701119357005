import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../layout/header';
import Footer from '../layout/footer';

import {
	books,
	booksIntro
} from '../../contents';


class Books extends Component {


	render() {
		return (
			<>
				<Header active="books" />
				<div className="page-content bg-white">

					<div className="content-area">
						<div className="container text-black">
							<h1>{booksIntro.title}</h1>
							<p>{booksIntro.description}</p>
							<div className="row">
								<div id="masonry" className="dlab-blog-grid-3 col-lg-12">
									<div className="row">
									{books.length === 0 && (<><h2 className='text-center'>There are no books published yet, please check back later. </h2> <p className='text-center'>Click <a style={{ color: "blue" }} href="https://anayooleru-newsletter.beehiiv.com/subscribe">here</a> to subscribe and keep up with when something drops</p></>)}

										{books.map((book, index) => {
											return (
												<div key={index} className="post card-container col-xl-3 col-lg-4 col-md-6 col-sm-6">
													<div className="blog-post blog-grid blog-rounded blog-effect1">
														<div className="dlab-post-media dlab-img-effect">
															<img src={book.image} alt="" />
														</div>
														<div className="dlab-info p-a20 border-1">

															<div className="dlab-post-title ">
																<h5 className="post-title">{book.title}</h5>
															</div>
															<div className="dlab-post-text">
																<p>{book.short_description}</p>
															</div>
															<div className="dlab-post-readmore">
																<a href={book.amazon_url}>Read on Amazon</a>
																<br />
																<a href={book.gumroad_url}>Read on Gumroad</a>
															</div>
														</div>
													</div>
												</div>
											)
										})}
									</div>
								</div>
								{/* <div className="pagination-bx clearfix col-md-12 text-center">
									<ul className="pagination">
										<li className="previous"><Link to={"#"}><i className="ti-arrow-left"></i> Prev</Link></li>
										<li className="active"><Link to={"#"}>1</Link></li>
										<li><Link to={"#"}>2</Link></li>
										<li><Link to={"#"}>3</Link></li>
										<li className="next"><Link to={"#"}>Next <i className="ti-arrow-right"></i></Link></li>
									</ul>
								</div> */}
							</div>
						</div>
					</div>

				</div>
				{/* <Footer /> */}
			</>
		)
	}
}
export default Books;