import React from 'react';
import { Link } from 'react-router-dom';
import { footer } from '../../contents';



const Footer = () => {
    const { title, terms_and_conditions, policy, faq } = footer;
    return (
        <>
            <footer className="site-footer">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 text-left "> <span>{title}</span> </div>
                            <div className="col-md-6 col-sm-6 text-right ">
                                <div className="widget-link ">
                                    <ul>
                                        {/* <li><Link to="/terms-and-conditions">{terms_and_conditions}</Link></li>
                                        <li><Link to="/privacy-policy">{policy}</Link></li> */}
                                        <li><Link to="/faq">{faq}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
};

export default Footer;
