import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
//import { Accordion, AccordionItem } from 'react-sanfona';
import { Accordion } from 'react-bootstrap';


const faqPersonalContent = [
    {
        question: "1. What is my Tech Stack?",
        answer: "ReactJs, ReduxJs, React Native, NodeJs, ExpressJs, PostgreSQL, MongoDB, TypeScript, AWS"
    },
    {
        question: "2. How can you contact me?",
        answer: `Send me an <a style="color:blue" target="_blank" href="mailto: anayo_oleru@outlook.com">email</a> or schedule a meeting on my <a style="color:blue" target="_blank" href="https://calendly.com/anayo_oleru/anayo-oleru-calendar?month=2022-12">calendar</a>`
    },
    {
        question: "3. Am I open to a new job opportunity? ",
        answer: `I am open to new opportunities, please reach me on LinkedIn, send me an <a style="color:blue" target="_blank" href="mailto: anayo_oleru@outlook.com">email</a> or schedule a call on my <a style="color:blue" target="_blank" href="https://calendly.com/anayo_oleru/anayo-oleru-calendar?month=2022-12">calendar</a>, let us talk more about it.`
    },
];

const faqMentorshipContent = [
    {
        question: "1. How do I hire you to complete a task?",
        answer: `If you need very urgent help, please send me an <a style="color:blue" target="_blank" href="mailto: anayo_oleru@outlook.com">email</a> or message me on LinkedIn. If it is not very urgent then you can schedule a call on my <a style="color:blue" target="_blank" href="https://calendly.com/anayo_oleru/anayo-oleru-calendar?month=2022-12">calendar</a> `
    },
    {
        question: "2. Live session vs Freelance Job?",
        answer: `
        <div>
        <h2>Live Session</h2>
        <p>You are able to communicate with me in a session room with video chat, screen sharing, and sometimes remote control tools. The cost will depend on the length of the session and the my rate.</p>

        <h2>Freelance Jobs</h2>

Freelance job is a way for you to pay me for additional help outside of mentoring sessions. This is also a convenient way for you to send a quick payment as a token of appreciation.

You can choose to pay a me immediately or to keep the funds in escrow.

If you need assistance with assignments (as long as it's permitted under your school's policy), you can receive help through live 1:1 mentoring sessions (receiving help through freelance jobs, code reviews or direct payments can be considered).
        </div>`
    },
    {
        question: "3. How can I set up my session environment?",
        answer:
            `
        <ol>
        <li> Install screen sharing & desktop remote control tools</li>
<p>Zoom is the video, screen sharing, and remote control tool I recommend. Please download Zoom <a style="color:blue" target="_blank" href="https://zoom.us/download">here</a>.</p>

<li>Try out a test session</li>
<p>To make sure that you've properly set up and understood how our live sessions works, please open and test your zoom.</p>

<li>Get your code environment, documents or questions ready</li>
<p>If you're fixing an issue on the code, get your code environment up and ready, if it's a document or web page, or questions get them up and ready. Once a session starts, the time starts counting.</p>
</ol>
        `
    },

    {
        question: "4. I'm having trouble with Zoom, any alternatives?",
        answer:
            `
        <p>Please make sure you’re using the latest version of Zoom and review their System Requirements to make sure your device can run this tool smoothly.

        If you’re still having troubles, we can try using Google Meet for our session.</p>
        `
    },
    {
        question: "5. Session Do's & Don'ts for Users",
        answer:
            `
            <div>
            <ol>
            <b>Please Do:</b>
<li>Make sure to set up a stable internet environment before entering the session</li>
<li>Make sure you've set up your payment method</li>
<li>Be on time if you booked a schedule with a me</li>
<li>Get your code environment or question or document ready</li>

<b>Please Don't</b>
<li>Ask or solicit me for free help</li>
<li>Create spam requests or send unrelated emails</li>
<li>Be offensive to me, I am also a human too</li>
            </ol>
            </div>
        `
    },
    {
        question: "6. Do you support NDAs?",
        answer:
            `
            <div>
            <p>Yes, I supply a non-disclosure agreement. My system supports secure, legally-binding electronic signatures.

            If you'd like to protect the confidentiality of the information you disclose to me or are concerned about the privacy and/or copyright of your project, be sure to <a style="color:blue" target="_blank" href="mailto: anayo_oleru@outlook.com">email</a> me an NDA beforehand.
            
            You can request I sign the NDA electronically.</p>
            </div>
        `
    },

    {
        question: "7. How do I leave a review for my session? Can I edit the review?",
        answer:
            `
            <div>
            <p>Yes, I would send you a review at the end of each session. You can add or edit your ratings and reviews.</p>
            </div>
        `
    },
];

const faqCoursesContent = [
    {
        question: "1. Web design aorem apsum dolor sit amet?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat ratione natus, reiciendis at debitis aliquid. Tenetur sunt id voluptatibus vitae "
    },
    {
        question: "2. Graphic design aorem apsum dolor ?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat ratione natus, reiciendis at debitis aliquid. Tenetur sunt id voluptatibus vitae "
    },
    {
        question: "3. Developement aorem apsum dolor sit amet ? ",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat ratione natus, reiciendis at debitis aliquid. Tenetur sunt id voluptatibus vitae "
    },
    {
        question: "4. True Responsiveness consectetuer adipiscing ? ",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat ratione natus, reiciendis at debitis aliquid. Tenetur sunt id voluptatibus vitae "
    },
    {
        question: "5. Claritas est etiam processus ?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat ratione natus, reiciendis at debitis aliquid. Tenetur sunt id voluptatibus vitae "
    },
]

// const bg = require('../../images/banner/bnr3.jpg')

const Faq = () => {
    const [activeDefault, setActiveDefault] = useState(-1);


    return (
        <>
            <Header />
            <div className="page-content bg-white">


                <div className="content-block">

                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="section-head text-black text-left">
                                <h3>Personal</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <Accordion className="dlab-accordion faq-2 box-sort-in m-b30  ">
                                        {faqPersonalContent.map((faq, i) => (
                                            <div className="panel">
                                                <Accordion.Item eventKey={`${i}`}>
                                                    <Accordion.Header as="h6">
                                                        {faq.question}
                                                    </Accordion.Header>
                                                    <Accordion.Body eventKey={`${i}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="section-head text-black text-left">
                                <h3>Mentorship</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <Accordion className="dlab-accordion faq-2 box-sort-in m-b30  ">
                                        {faqMentorshipContent.map((faq, i) => (
                                            <div className="panel">
                                                <Accordion.Item eventKey={`${i}`}>
                                                    <Accordion.Header as="h6">
                                                        {faq.question}
                                                    </Accordion.Header>
                                                    <Accordion.Body eventKey={`${i}`}>

                                                        <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        ))}
                                    </Accordion>



                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-head text-black text-left">
                                <h3>Courses</h3>
                            </div>
                            <div className="row m-b30">
                                <div className="col-lg-12 col-sm-12">

                                    <Accordion className="dlab-accordion faq-2 accord-2 box-sort-in m-b30 ">
                                        {faqCoursesContent.map((faq, i) => (
                                            <div className="panel">
                                                <Accordion.Item eventKey={`${i}`}>
                                                    <Accordion.Header as="h6">
                                                        {faq.question}
                                                    </Accordion.Header>
                                                    <Accordion.Body eventKey={`${i}`}>
                                                        <div>{faq.answer}</div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Faq;
