import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../layout/header';
import Footer from '../layout/footer';
import { openSourceIntro, openSource } from "../../contents/openSource";

class OpenSource extends Component {


    render() {
        return (
            <>
                <Helmet>
                    <title>{`Anayo Oleru | Open Source Projects`}</title>
                    <meta
                        name="description"
                        content="Anayo Samson Oleru is a Software Engineer based in Wales🏴󠁧󠁢󠁷󠁬󠁳󠁿, United Kingdom🇬🇧.
                        He with a track record in Web & Mobile Engineering, Cloud Computing and mentorship. He is passionate about mentoring and training other developers.
                        He is a Google Mentor and Judge for Google African Developer Scholarship 2020-2022 and a Community Manager at Open Source Community Africa for Uyo state Nigeria.
                        He has over 400+ hours of software engineering mentorship sessions and have trained and mentored over 120+ students from different parts of the world🌎🌍🌏.
                        
                        He has worked with multiple teams and startups across the world to build powerful, modern, performant and scalable web and mobile applications. He also creates YouTube videos📷 on Programming and Tech on his YouTube Channel. He has given talks/workshops at developer conferences across various locations.
                        
                        To contact me, send me an email or schedule a paid or free 10 min call on my calendar"
                    />
                    <meta
                        name="keywords"
                        content="Open Source, NPM Packages, AWS, Code Mentor, Coding Help, Pair Programming, Software Expert, Top Developer, Freelance Job"
                    />
                </Helmet>
                <Header active="open-source" />
                <div className="page-content bg-white">
                    <div className="content-area">
                        <div className="container">
                            <h2>{openSourceIntro.title}</h2>
                            <p>{openSourceIntro.description}</p>
                            <div className="row">
                                <div className="col-lg-12">

                                    {openSource.map((source, index) => {
                                        return (
                                            <div className="blog-post blog-md clearfix">
                                                <div className="dlab-post-media">
                                                    <a href={source.npm_url}><img style={{
                                                        width: "100%",
                                                        height: 300,
                                                        objectFit: "contain"
                                                    }} src={source.image} alt="" /></a>
                                                </div>
                                                <div className="dlab-post-info">

                                                    <div className="dlab-post-title">
                                                        <h4 className="post-title">{source.title}</h4>
                                                    </div>
                                                    <div className="dlab-post-text">
                                                        <p>{source.short_description}</p>
                                                    </div>
                                                    <div className="dlab-post-readmore">
                                                        <a className="site-button btnhover21 black m-r10 m-b10" target="_blank" href={source.npm_url} rel="noreferrer">
                                                            View
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* <div className="pagination-bx clearfix m-b30 text-center">
                                        <ul className="pagination">
                                            <li className="previous"><Link to="/#"><i className="ti-arrow-left"></i> Prev</Link></li>
                                            <li className="active"><Link to="/#">1</Link></li>
                                            <li><Link to="/#">2</Link></li>
                                            <li><Link to="/#">3</Link></li>
                                            <li className="next"><Link to="/#">Next <i className="ti-arrow-right"></i></Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    };
};

export default OpenSource;
