import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../layout/header';
import Footer from '../layout/footer'
import ScrollToTop from '../element/scrollToTop';
import CounterSection2 from '../element/counterSection2';
import Testimonial10 from '../element/testimonial10';

import { reviews } from '../../contents';

class Mentorship extends Component {

    render() {
        return (
            <>
                <Helmet>
                    <title>Anayo Oleru | Code mentorship | Get 1:1 programming help, hire developers, learn to code </title>
                    <meta
                        name="description"
                        content="Anayo Oleru Code mentorship is the largest community for developer mentorship and an on-demand marketplace for software developers. Get instant coding help, build projects faster, and read programming tutorials."
                    />
                    <meta
                        name="keywords"
                        content="Code Mentor, Coding Help, Pair Programming, Software Expert, Top Developer, Freelance Job"
                    />
                </Helmet>
                <Header active="mentorship" />
                <div className="page-content bg-white">

                    <div className="dlab-bnr-inr overlay-black-middle cargo-banner" style={{ backgroundImage: "url(" + "https://images.unsplash.com/photo-1513258496099-48168024aec0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry text-white">
                                <h1 className="title">Get the best personal live mentorship</h1>
                                <div className="bottom-link"><h3><Link to="#" className="text-white">Schedule a free 10 min call with me</Link></h3></div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <a href="https://calendly.com/anayo_oleru/anayo-oleru-calendar" target={"_blank"} className="site-button btnhover17 btn-block w-200  radius-no" style={{ backgroundColor: "yellow", color: "black" }}>Schedule a free call</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner-2 exhibition-bx">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <img src={"https://images.unsplash.com/photo-1555747590-6d40108fae52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"} alt="" />
                                    </div>
                                    <div className="col-lg-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="content-bx1">
                                            <div className="section-head">
                                                <h2 className="title">One-on-one live mentorship</h2>
                                                <p>
                                                    <ul>
                                                        <li>With my help/guide as an expert, you will  be able to debug like an expert</li>
                                                        <li>I personalize learning experience of my mentees</li>
                                                        <li>Get answers to your complex problems</li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <a href="https://calendly.com/anayo_oleru/anayo-oleru-calendar" target={"_blank"} className="site-button btnhover21 black m-r10 m-b10">Schedule a free call</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full content-inner-2 exhibition-bx">
                            <div className="container">
                                <div className="row align-items-center">

                                    <div className="col-lg-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="content-bx1">
                                            <div className="section-head">
                                                <h2 className="title">Project-based freelance work</h2>
                                                <p>I am an expert for:
                                                    <ul>
                                                        <li>on-demand code review</li>
                                                        <li>building features for your existing product</li>
                                                        <li>Turning your idea into an MVP</li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <a href="https://calendly.com/anayo_oleru/anayo-oleru-calendar" target={"_blank"} className="site-button btnhover21 black m-r10 m-b10">Get in touch with me</a>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <img src={"https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDJ8fGNvZGUlMjBwcm9qZWN0JTIwZmVhdHVyZXN8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full content-inner-2 exhibition-bx">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <img src={"https://images.unsplash.com/photo-1612117229486-78abff6d84c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"} alt="" />
                                    </div>
                                    <div className="col-lg-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="content-bx1">
                                            <div className="section-head">
                                                <h2 className="title">Invest in your child, by inspiring them to pursue a career in tech</h2>
                                                <p>Children mentees learn quickly about tech and programming using my curriculum and method. Helping children mentees:
                                                    <ul>
                                                        <li>acquire skills and confidence in technology</li>
                                                        <li>Learn what they need to build great product from a very young age</li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <a href="https://calendly.com/anayo_oleru/anayo-oleru-calendar" target={"_blank"} className="site-button btnhover21 black m-r10 m-b10">Schedule a free call</a>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <CounterSection2 />

                        <div className="section-full bg-gray content-inner-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="sort-title clearfix text-center">
                                            <h2>Recent Reviews from Mentees</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-content">
                                    <Testimonial10 reviews={reviews} />
                                </div>

                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="sort-title clearfix text-center">
                                            <h2>How Mentorship Works</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="icon-bx-wraper service-box style5 shadow fly-box">
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Schedule a kickoff call</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="icon-bx-wraper service-box style5 shadow fly-box">
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Start Mentorship</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="icon-bx-wraper service-box style5 shadow fly-box">
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Graduate job-ready</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <ScrollToTop className="style3" />
            </>
        )
    };
};

export default Mentorship;
