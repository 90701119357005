import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import ServicesSlider1 from '../element/servicesSlider1';
import { courses } from '../../contents';

class CourseRead extends Component {
    state = {
        course: {}
    };

    componentDidMount() {

        Object.entries(courses).map((value, key) => {

            if (value[1].slug === this.props.match.params.slug) {
                this.setState({ course: value[1] });

            }
        });
    };

    render() {
        const { course } = this.state;
        return (
            <>
                <Helmet>
                    <title>{course.title}</title>
                    <meta
                        name="description"
                        content={course.title}
                    />
                    <meta
                        name="keywords"
                        content={course.tags.map((co, i) => co)}
                    />
                </Helmet>
                <Header />
                <div className="page-content bg-white">
                    <div className="content-area">
                        <div className="container max-w900">
                            <div className="blog-post blog-single">

                                <div className="dlab-post-media dlab-img-effect zoom-slow">
                                    <img src={course.image} alt="" />
                                </div>
                                <div className="dlab-post-title ">
                                    <h2 className="post-title m-t0">{course.title}</h2>
                                </div>
                                <div className="dlab-post-text">
                                    <p>{course.short_description}</p>

                                </div>

                                {/* <div className="dlab-post-tags clear">
                                    <div className="post-tags">
                                        <Link to="/#">Child </Link> <Link to="/#">Eduction </Link> <Link to="/#">Money </Link> <Link to="/#">Resturent </Link>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    <div className="section-full bg-gray content-inner about-carousel-ser">
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="title">Episodes</h2>
                                <p>{course.episode_intro}</p>
                            </div>

                            <ServicesSlider1
                                slidesToShow={3}
                                data={course.episodes} />

                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    };
};

export default CourseRead;
